import { useContext } from "react";
import { __RouterContext as RouterContext } from "react-router";

export function useRouter() {
  return useContext(RouterContext);
}

export function useCurrentImagebankId() {
  const { match } = useRouter();

  const currentImagebankId = match.params.bankId;
  return currentImagebankId;
}
