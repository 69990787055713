import * as Msal from "msal";

export default class AuthService {
  constructor() {
    // let PROD_REDIRECT_URI = 'https://imagebank.io/admin/login';
    let redirectUri = "https://imagebank.io/admin/login";
    // let redirectUri = "http://localhost:3000/login";
    // if (window.location.hostname !== '127.0.0.1') {
    //   redirectUri = PROD_REDIRECT_URI;
    // }
    this.applicationConfig = {
      clientID: "07d2c472-b28a-48f3-8fa7-37ab2d128d40",
      graphScopes: ["user.read"]
    };
    this.app = new Msal.UserAgentApplication(
      this.applicationConfig.clientID,
      "",
      () => {
        // callback for login redirect
      },
      {
        redirectUri
      }
    );
  }
  login = () => {
    return this.app.loginPopup(this.applicationConfig.graphScopes).then(
      idToken => {
        return idToken;
      },
      () => {
        return null;
      }
    );
  };
  logout = () => {
    this.app.logout();
  };
  getToken = () => {
    return this.app.acquireTokenSilent(this.applicationConfig.graphScopes).then(
      accessToken => {
        return accessToken;
      },
      error => {
        return this.app
          .acquireTokenPopup(this.applicationConfig.graphScopes)
          .then(
            accessToken => {
              return accessToken;
            },
            err => {
              console.error(err);
            }
          );
      }
    );
  };
}
