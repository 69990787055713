import React, { createContext, useContext, useReducer } from 'react';

const StateContext = createContext();

const reducer = (state, action) => {
  console.log({ state }, { action });
  switch (action.type) {
    case 'set_current_folder': {
      return {
        ...state,
        currentFolder: action.payload
      };
    }
    case 'set_available_banks': {
      return {
        ...state,
        imageBanks: action.payload
      };
    }
    case 'set_visible_images': {
      return {
        ...state,
        visibleImages: action.payload
      };
    }
    case 'set_query': {
      return {
        ...state,
        query: action.payload
      };
    }
    case 'show_image': {
      return {
        ...state,
        activeImage: action.payload
      };
    }
    case 'deselect_all': {
      return {
        ...state,
        selectedImages: []
      };
    }
    case 'select_image': {
      let selectedImages;
      if (Array.isArray(action.payload)) {
        // Use a set to avoid duplicates in case something was already selected
        selectedImages = [
          ...new Set(state.selectedImages.concat(action.payload))
        ];
      } else {
        if (state.selectedImages.find(item => item === action.payload)) {
          // Remove if already selected (eg. "deselect")
          selectedImages = state.selectedImages.filter(
            imageId => imageId !== action.payload
          );
        } else {
          // Add to selected images
          selectedImages = [...state.selectedImages, action.payload];
        }
      }
      return {
        ...state,
        selectedImages
      };
    }

    default:
      return state;
  }
};

const initialState = {
  activeImage: null,
  currentFolder: null,
  query: '',
  visibleImages: [],
  selectedImages: [],
  imageBanks: []
};

export const StateContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => {
  const context = useContext(StateContext);

  if (context === undefined) {
    throw new Error(
      'useStateContext() must be used within a StateContextProvider'
    );
  }

  return context;
};
