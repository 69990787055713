import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { useImagebank } from '../hooks/use-imagebank';
import { useCurrentImagebankId } from '../hooks/router-hooks';
import Loading from '../components/Loading';
import { useUserContext } from './user';

const ImagebankContext = React.createContext();

export const ImagebankContextProvider = ({ children }) => {
  const currentImagebankId = useCurrentImagebankId();
  const { user } = useUserContext();
  const { data, loading } = useImagebank(
    currentImagebankId || user.imagebanks[0].id
  );

  if (currentImagebankId === undefined) {
    return <Redirect to={`/${user.imagebanks[0].id}`}></Redirect>;
  }

  if (data === undefined) {
    return <Loading></Loading>;
  }

  return (
    <ImagebankContext.Provider
      value={{
        ...data.imagebank
      }}
    >
      {loading ? <Loading></Loading> : children}
    </ImagebankContext.Provider>
  );
};

export const useImagebankContext = () => {
  const ctx = useContext(ImagebankContext);

  if (ctx === undefined) {
    throw new Error(
      'useImagebankContext() must be used withing a ImagebankContextProvider'
    );
  }

  return ctx;
};
