import React, { useEffect, useContext } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { Redirect } from "react-router-dom";
import { useStateContext } from "./state";
import Loading from "../components/Loading";

const CURRENT_USER_QUERY = gql`
  query CURRENT_USER_QUERY {
    me {
      id
      email
      role
      imagebanks {
        id
        name
        tid
      }
    }
  }
`;

const UserContext = React.createContext();

export const UserContextProvider = ({ children }) => {
  const { dispatch } = useStateContext();
  const { data, loading, error } = useQuery(CURRENT_USER_QUERY);

  useEffect(() => {
    if (data && data.me) {
      dispatch({
        type: "set_available_banks",
        payload: data.me.imagebanks
      });
    }
  }, [data]);
  if (error) return <Redirect to="/login" />;
  if (loading) return <Loading></Loading>;
  return (
    <UserContext.Provider
      value={{
        user: data ? data.me : undefined
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const ctx = useContext(UserContext);

  if (ctx === undefined) {
    throw new Error(
      "useUserContext() must be used withing a UserContextProvider"
    );
  }

  return ctx;
};
