import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

const SINGLE_IMAGEBANK_QUERY = gql`
  query SINGLE_IMAGEBANK_QUERY($id: ID!) {
    imagebank(id: $id) {
      id
      name
      limit
      tid
      users {
        id
        email
        role
        lastLogin
      }
    }
  }
`;

export const useImagebank = bankId => {
  const { data, loading, error } = useQuery(SINGLE_IMAGEBANK_QUERY, {
    variables: {
      id: bankId
    }
  });

  return { data, loading, error };
};
