import { gql } from "apollo-boost";
export const ADMIN_LOGIN_MUTATION = gql`
  mutation ADMIN_LOGIN_MUTATION($idToken: String!) {
    adminLogin(idToken: $idToken) {
      token
      user {
        id
        email
        imagebanks {
          id
          name
        }
      }
    }
  }
`;
