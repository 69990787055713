import React, { lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink, Observable } from "apollo-link";
import { ApolloProvider } from "react-apollo";
import "./index.css";
import Login from "./components/Login";
import Loading from "./components/Loading";
import * as serviceWorker from "./serviceWorker";
import { StateContextProvider } from "./context/state";
import { UserContextProvider } from "./context/user";
import { ImagebankContextProvider } from "./context/imagebank";
const App = lazy(() => import("./components/App"));

const request = async operation => {
  const token = await localStorage.getItem("imagebank_token");
  operation.setContext({
    headers: {
      authorization: token || ""
    }
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    requestLink,
    createUploadLink({
      // uri: 'http://localhost:4000',
      // uri: "https://aqueous-coast-68140.herokuapp.com/"
      //
      uri: "https://aqueous-coast-68140.herokuapp.com"
    })
  ]),
  cache: new InMemoryCache()
});

// Pass your GraphQL endpoint to uri
// const client = new ApolloClient({
//   uri: 'http://localhost:4000',
//   //uri: "https://api-prisma-imagebank.now.sh",
//   request: async operation => {
//     const token = localStorage.getItem('imagebank_token');
//     operation.setContext({
//       headers: {
//         authorization: token || ''
//       }
//     });
//   }
// });

const Routes = () => (
  <ApolloProvider client={client}>
    <React.Suspense fallback={<Loading />}>
      <Router basename="/admin">
        <Switch>
          <Route path="/login" render={props => <Login {...props} />} />
          <Route path="/:bankId?">
            <StateContextProvider>
              <UserContextProvider>
                <ImagebankContextProvider>
                  <App />
                </ImagebankContextProvider>
              </UserContextProvider>
            </StateContextProvider>
          </Route>
        </Switch>
      </Router>
    </React.Suspense>
  </ApolloProvider>
);

ReactDOM.render(<Routes />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
