import React from "react";
import styled from "styled-components";
import { Segment, Message } from "semantic-ui-react";
import { Mutation } from "react-apollo";
import SignInButton from "../assets/ms_signin.svg";
import AuthService from "../lib/auth";
import Loading from "./Loading";
import { ADMIN_LOGIN_MUTATION } from "../mutations/admin-login";

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

class Login extends React.Component {
  state = {
    error: this.props.location.state ? this.props.location.state.error : ""
  };

  authService = new AuthService();

  login = loginPrisma => {
    this.authService.login().then(
      async idToken => {
        if (idToken) {
          // Her må vi kalle graphql
          const dbUser = await loginPrisma({
            variables: {
              idToken
            }
          });

          // lagre token til bruk senere
          localStorage.setItem("imagebank_token", dbUser.data.adminLogin.token);
          this.props.history.push("/");
        } else {
          //Display Error
        }
      },
      //some kind of callback?
      () => {
        //Display error
      }
    );
  };

  render() {
    return (
      <Mutation mutation={ADMIN_LOGIN_MUTATION}>
        {(prismaLogin, { loading, error }) => {
          if (loading) {
            return <Loading />;
          }

          // display error, but stay on same page
          return (
            <LoginWrapper>
              <h3>Imagebank Admin</h3>
              <Segment>
                <p>
                  Click the button below to log in with your Microsoft Account
                </p>
                <img
                  src={SignInButton}
                  alt="Sign in with Microsoft"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.login(prismaLogin)}
                />
              </Segment>

              {this.state.error ||
              (error && error.message === "GraphQL error: Access denied") ? (
                <Message negative>
                  <Message.Header>Access denied</Message.Header>
                  <p>
                    We're sorry but you don't have access to the admin area.
                  </p>
                </Message>
              ) : null}
            </LoginWrapper>
          );
        }}
      </Mutation>
    );
  }
}

export default Login;
